export default {
  GetPanelDetails: "GetPanelDetails",
  TryPassword: "TryPassword",
  GetPanelScripts: "GetPanelScripts",
  ExecScript: "ExecScript",
  CancelScript: "CancelScript",
  GetVmScreenshot: "GetVmScreenshot",
  GetMonitoringData: "GetMonitoringData",
  GetPlanTemplates: "GetPlanTemplates",
  EditPlanTemplate: "EditPlanTemplate",
  GetCdDrives: "GetCdDrives",
  InsertISO: "InsertISO",

  PowerOn: "PowerOn",
  PowerOff: "PowerOff",
  PowerReset: "PowerReset",
  VmSuspend: "VmSuspend",
  EjectISO: "EjectISO",
  Delete: "Delete",
  Deploy: "Deploy",
};
