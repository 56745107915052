export default {
  SetPanelDetails: "SetPanelDetails",
  SetAllMachines: "SetAllMachines",
  RequirePass: "RequirePass",
  SetCustomerDetails: "SetCustomerDetails",
  SetPanelScripts: "SetPanelScripts",
  ExecScriptResult: "ExecScriptResult",
  ExecScriptUpdate: "ExecScriptUpdate",
  SetStatus: "SetStatus",
  SetServiceSuspended: "SetServiceSuspended",
  SetOsPassword: "SetOsPassword",
  SetIpAddress: "SetIpAddress",
  SetActions: "SetActions",
  Reload: "Reload",
  Message: "Message",
  Action: "Action",
  SetCdDrives: "SetCdDrives",
  SetPlanTemplates: "SetPlanTemplates",
  SetVmScreenshot: "SetVmScreenshot",
  SetMonitoringData: "SetMonitoringData",

  CreateSession: "CreateSession",
};
