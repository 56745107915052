import qs from "./qs";
import Time from "./Time";

function ConvertArrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export default {
  qs,
  Time,
  ConvertArrayBufferToBase64,
};
